import React, { useEffect, useState } from "react";
import "./Statisics.css";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import CopyToClipboard from "react-copy-to-clipboard";

//core ui imports
import {
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionBody,
} from "@coreui/react";
import { localeFixed } from "../../config/helpers";
import { url } from "../../config";
import { useSaleUserData } from "../../hooks/useContractData";
import { formatUnits } from "ethers/lib/utils";

import { useAddress } from "../../hooks/useAddress";
import { useLocation } from "react-router";
import { useSaleEligible } from "../../hooks/useSaleEligible";

const UserStatistics = () => {

    const { address } = useAddress()
    const [id, setid] = useState(0)
    const location = useLocation()
    

    const [usdtInvested, setUsdtInvested] = useState(0)
    const [usdcInvested, setUsdcInvested] = useState(0)
    const [hfgBought, setHfgBought] = useState(0)
    const [referralBonus, setReferralBonus] = useState(0)
    const [totalInvested, setTotalInvested] = useState(0)

    const { userInfo } = useSaleUserData(id)
    const { eligible } = useSaleEligible(id)

    useEffect(() => {
        if (userInfo) {
            setUsdtInvested(formatUnits(userInfo.usdtSpent, 6))
            setUsdcInvested(formatUnits(userInfo.usdcSpent, 6))
            setHfgBought(formatUnits(userInfo.hfBought, 18))
            setReferralBonus(formatUnits(userInfo.hfBonus, 18))
            setTotalInvested(formatUnits(userInfo.totalSpent, 6))
        }
    }, [userInfo])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        setid(id)
    }, [])


    return (
        <>
            <CAccordion flush>
                <CAccordionItem itemKey={2}>
                    <CAccordionHeader>
                        {" "}
                        <h2>USER STATISTICS</h2>
                        <div className="row justify-content-center align-items-center w-100 mx-auto">
                            <div className="col-12 col-md-3 text-center stat-info">
                                <div className="m-0 inputlabel">
                                    <p>HFG Bought</p>
                                    <strong>
                                        {localeFixed(hfgBought)} HFG
                                    </strong>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 text-center stat-info">
                                <div className="m-0 inputlabel">
                                    <p>USD Invested</p>
                                    <strong>
                                        ${localeFixed(totalInvested)}
                                    </strong>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 text-center stat-info">
                                <div className="m-0 inputlabel">
                                    <p>Refer Bonus</p>
                                    <strong>
                                        {localeFixed(referralBonus)} HFG
                                    </strong>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 text-center stat-info">
                                <p className="m-0 viewbtn d-flex align-items-center justify-content-center mx-auto mx-md-0 ms-md-auto">
                                    View Details{" "}
                                    <span className="arrow d-inline-block ms-2">
                                        {" "}
                                        &#10095;
                                    </span>
                                </p>
                            </div>
                        </div>
                    </CAccordionHeader>
                    <CAccordionBody>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 text-center">
                                <div className="box">
                                    <p>${localeFixed(totalInvested)}</p>
                                    <p>Total Invested</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 text-center">
                                <div className="box">
                                    <p>After 60 Days, 10% each month</p>
                                    <p>Vesting Period</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 text-center">
                                <div className="box">
                                    <p>upto 4% on levels</p>
                                    <p>Referral</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 text-center">
                                <div className="box">
                                    <p>{localeFixed(usdtInvested)}</p>
                                    <p>Total USDT</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 text-center">
                                <div className="box">
                                    <strong>
                                        {localeFixed(usdcInvested)}
                                    </strong>
                                    <p>Total USDC</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 text-center">
                                <div className="box">
                                    <strong>
                                        {localeFixed(hfgBought)}
                                    </strong>
                                    <p>Total HFG Bought</p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                {(eligible && address) && (
                                    <div className="box">
                                        <strong>Copy Referral Link</strong>
                                        <CopyToClipboard
                                            text={`${url}?referrer=${address}`}
                                        >
                                            <p
                                                className="copyReferralLink"
                                                onClick={() => {
                                                    toast.success("Copied to clipboard")
                                                }}
                                            >{`${url}?referrer=${address.substring(
                                                0,
                                                3
                                            )}...${address.slice(-3)}`}</p>
                                        </CopyToClipboard>
                                    </div>
                                )}
                                {
                                    !eligible && (
                                        <div className="box">
                                            <strong>Referral Link</strong>
                                            <p>
                                                Not eligible for referral bonus. Minimum investment of $10 required.
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </CAccordionBody>
                </CAccordionItem>
            </CAccordion>
        </>
    );
};

export default UserStatistics;

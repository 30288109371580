import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import "@coreui/coreui/dist/css/coreui.min.css";
import Sale from "./Pages/Sale/Sale";
import Ref from "./Pages/RefDetails/RefDetails";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { useReferralStored } from "./hooks/useContractData";
import { Toaster } from "react-hot-toast";

import SaleDetails from "./Pages/SaleDetails/SaleDetails";

const chains = [polygon]
const projectId = 'b35f0b2ea3caf46b366b71699d982b58'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function AppRoutes() {
  useReferralStored()
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Sale />} />
            <Route path="saledetails" element={<SaleDetails />} />
            <Route path="referdetails" element={<Ref />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <BigBangStarField
        className="bgBody"
        numStars={150}
        maxStarSpeed={5}
        scale={1}
        starColor={"252, 255, 255"}
      />
      <ReactPlayer
        className='react-player bgVideoBody'
        url='https://ik.imagekit.io/imgdata/hashfair/bgVideo.mp4?updatedAt=1687944266890'
        width='100%'
        height='100%'
        muted
        loop
        playing="true"
        playsinline="true"
      /> */}

      {/* <Lottie
        loop
        className='react-player bgVideoBody'
        animationData={hashfairbg}
        play
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      /> */}
    </>

  );
}

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AppRoutes />
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
    </>
  )
}

export default App;

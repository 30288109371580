import React, { useCallback, useEffect, useState } from 'react'
import './BuyOc.css'
import { Toaster, toast } from 'react-hot-toast';
import { useBuy, useContractCall, useSaleBuy, useSaleContractData, useSaleUserData } from '../../hooks/useContractData';
import { usdcToken, usdtToken } from '../../config';
import { ethers } from 'ethers';
import { erc20ABI } from 'wagmi';
import { localeFixed } from '../../config/helpers';
import { formatUnits } from 'viem';
import { addToken } from '../../utils/addToken';
import { useLocation } from 'react-router';
import { rounds } from '../../config/rounds';

const BuyOc = () => {

    const [id, setid] = useState(0)
    const location = useLocation()


    const [usdtInput, setUsdtInput] = useState(0)
    const [usdcInput, setUsdcInput] = useState(0)
    const [hfgByUsdt, setHfgByUsdt] = useState(0)
    const [hfgByUsdc, setHfgByUsdc] = useState(0)
    const [sold, setSold] = useState(0)

    const [approvingUSDT, setApprovingUSDT] = useState(false)
    const [approvingUSDC, setApprovingUSDC] = useState(false)

    const [buyingWithUSDT, setBuyingWithUSDT] = useState(false)
    const [buyingWithUSDC, setBuyingWithUSDC] = useState(false)

    const { usdcBalance, usdtBalance, usdcAllowance, usdtAllowance, userInfo } = useSaleUserData(id)

    const { execute: usdtApprove } = useContractCall(usdtToken, erc20ABI, "approve", [id, ethers.constants.MaxUint256])
    const { execute: usdcApprove } = useContractCall(usdcToken, erc20ABI, "approve", [id, ethers.constants.MaxUint256])

    const { execute: buyHFGWith } = useSaleBuy(id)

    const saleCap = Number(rounds.filter(data => data.address === id)[0]?.ocAllotted.replace(/,/g, ''));
    const salePrice = Number(rounds.filter(data => data.address === id)[0]?.price);

    const { result: data, isLoading } = useSaleContractData(id)
    useEffect(() => {
        if (!isLoading && data) {
            const sale = data
            const totalRaised = parseFloat(formatUnits((sale.totalRaised), 6))
            setSold(totalRaised)
        }
    }, [data, isLoading])


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        setid(id)
    }, [])

    const approveUSDTCallback = useCallback(async () => {
        try {
            setApprovingUSDT(true)
            const promist = await usdtApprove?.()
            console.log(promist);

            if (promist) {
                await toast.promise(promist(), { loading: "Approving USDT...", success: "USDT Approved", error: "Failed to approve USDT" })
            }
            // toast.success("USDT Approved")
            setApprovingUSDT(false)
        } catch (err) {
            setApprovingUSDT(false)
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
        }
    }, [usdtApprove, setApprovingUSDT])

    const approveUSDCCallback = useCallback(async () => {
        try {
            setApprovingUSDC(true)
            const promise = await usdcApprove?.()
            if (promise) {
                await toast.promise(promise(), { loading: "Approving USDC...", success: "USDC Approved", error: "Failed to approve USDC" })
            }
            setApprovingUSDC(false)
            // toast.success("USDC Approved")
        } catch (err) {
            setApprovingUSDC(false)
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
        }
    }, [usdcApprove, setApprovingUSDC])

    const buyWithUSDT = useCallback(async () => {
        try {
            setBuyingWithUSDT(true)
            const txn = await buyHFGWith?.(usdtToken, String(usdtInput))
            if (txn) {
                await toast.promise(txn(), { loading: "Buying HFG...", success: "HFG Bought", error: "Failed to buy HFG" })
                // await txn();
                // toast.error("Transaction reverted")
                setUsdtInput(0)
            }
            setBuyingWithUSDT(false)
        } catch (err) {
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            setBuyingWithUSDT(false)
        }
    }, [usdtInput, buyHFGWith])

    const buyWithUSDC = useCallback(async () => {
        try {
            setBuyingWithUSDC(true)
            const txn = await buyHFGWith?.(usdcToken, String(usdcInput))
            if (txn) {
                // console.log(txn)
                await toast.promise(txn(), { loading: "Buying HFG...", success: "HFG Bought", error: "Failed to buy HFG" })
                // await txn();
                // toast.error("Transaction reverted")
                setUsdcInput(0)
            }
            setBuyingWithUSDC(false)
            // setUsdcInput(0)
        } catch (err) {
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            setBuyingWithUSDC(false)
        }
    }, [usdcInput, buyHFGWith])

    const maxUSDT = useCallback(() => {
        const canInvest = (saleCap * salePrice) - sold
        setUsdtInput(canInvest > usdtBalance ? usdtBalance : canInvest)
    }, [usdtBalance, setUsdtInput, userInfo])

    const maxUSDC = useCallback(() => {
        const canInvest = (saleCap * salePrice) - sold
        setUsdcInput(canInvest > usdcBalance ? usdcBalance : canInvest)
    }, [usdcBalance, setUsdcInput, userInfo])

    const inputUSDT = useCallback((e) => {
        const inputValue = e.target.value;
        const trimmedValue = inputValue.replace(/^0+/, '');

        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(trimmedValue)) {
            // setValue(trimmedValue);
            setUsdtInput(trimmedValue)
        }

    }, [setUsdtInput])

    const inputUSDC = useCallback((e) => {
        const inputValue = e.target.value;
        const trimmedValue = inputValue.replace(/^0+/, '');

        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(trimmedValue)) {
            // setValue(trimmedValue);
            setUsdcInput(trimmedValue)
        }

    }, [setUsdcInput])

    useEffect(() => {
        // calc hfg by usdt
        if (usdtInput) {
            setHfgByUsdt(usdtInput / salePrice)
        } else {
            setHfgByUsdt(0)
        }
    }, [usdtInput])

    useEffect(() => {
        // calc hfg by usdt
        if (usdcInput) {
            setHfgByUsdc(usdcInput / salePrice)
        } else {
            setHfgByUsdc(0)
        }
    }, [usdcInput])



    // Sale full toaster for round 1 to 4 
    const notify = () => toast.error('Sale is Full')


    return (
        <section className="buyOc" id='buySellSection'>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />


            <div className="oc_container">
                <div className="title">
                    <h2>BUY HFG</h2>
                    {/* <button onClick={userdetails}>user details </button> */}
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-4">
                        <div className="buyOcbox">
                            <strong className="topttl">Enter USDT Amount <span>(Available: {usdtBalance || 0} )</span></strong>
                            <div className='row'>
                                <div className='col-9 pe-0'>
                                    <input type="number" onInput={inputUSDT} value={usdtInput} placeholder="0.00" />
                                    <div className="oc_row align-items-center space-between">
                                        <p>You will receive <b>{localeFixed(hfgByUsdt)}</b> HFG</p>
                                        {/* <span>Min $10</span> */}
                                    </div>
                                </div>
                                <div className='col-3 m-0 p-0'>
                                    <button class="maxBtn m-0" onClick={maxUSDT}>Max</button>
                                </div>
                            </div>
                            <div class="buttonGrp">
                                {((parseFloat(usdtAllowance) === 0) || (parseFloat(usdtAllowance) < inputUSDT && parseFloat(usdtAllowance) > 9)) && <button class="oc_btn" disabled={approvingUSDT} onClick={approveUSDTCallback}>
                                    {approvingUSDT ? "Approving..." : "Approve USDT"}
                                </button>}
                                {(parseFloat(usdtAllowance) !== 0 || parseFloat(usdtAllowance) >= inputUSDT) && <button class="oc_btn" onClick={buyWithUSDT} disabled={buyingWithUSDT}>
                                    {buyingWithUSDT ? "Buying..." : "Buy with USDT"}
                                </button>}
                                {usdtAllowance > 0 ||
                                    <button class="oc_btn" onClick={() => {
                                        addToken({
                                            data: {
                                                address: usdtToken,
                                                symbol: 'USDT',
                                                decimals: 6,
                                                name: "USDT"
                                            }
                                        })
                                    }}>Add USDT</button>}
                            </div>
                            {/* {
                                !address && <div class="buttonGrp">
                                    <button class="oc_btn" onClick={doConnectProfile}>
                                        Connect Wallet
                                    </button>
                                </div>
                            } */}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-4">
                        <div className="buyOcbox">
                            <strong className="topttl">Enter USDC Amount <span>(Available: {usdcBalance || 0} )</span></strong>
                            <div className='row'>
                                <div className='col-9 pe-0'>
                                    <input type="number" min={0} onInput={inputUSDC} value={usdcInput} placeholder="0.00" />
                                    <div className="oc_row align-items-center space-between">
                                        <p>You will receive <b>{localeFixed(hfgByUsdc)}</b> HFG</p>
                                        {/* <span>Min $10</span> */}
                                    </div>
                                </div>
                                <div className='col-3 m-0 p-0'>
                                    <button class="maxBtn m-0" onClick={maxUSDC}>Max</button>
                                </div>
                            </div>

                            <div class="buttonGrp">
                                {(parseFloat(usdcAllowance) === 0 || parseFloat(usdcAllowance) < (inputUSDC && parseFloat(usdcAllowance) > 9)) && <button class="oc_btn" disabled={approvingUSDC} onClick={approveUSDCCallback}>
                                    {approvingUSDC ? "Approving..." : "Approve USDC"}
                                </button>}

                                {(parseFloat(usdcAllowance) !== 0 || parseFloat(usdcAllowance) >= inputUSDC) && <button class="oc_btn" onClick={buyWithUSDC} disabled={buyingWithUSDC}>
                                    {buyingWithUSDC ? "Buying..." : "Buy with USDC"}
                                </button>}
                                {usdcAllowance > 0 ||
                                    <button class="oc_btn" onClick={() => {
                                        addToken({
                                            data: {
                                                address: usdcToken,
                                                symbol: 'USDC',
                                                decimals: 6,
                                                name: "USDC"
                                            }
                                        })
                                    }}>Add USDC</button>}
                            </div>
                            {/* {
                                !address && <div class="buttonGrp">
                                    <button class="oc_btn" onClick={doConnectProfile}>
                                        Connect Wallet
                                    </button>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuyOc


import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { formatEther, formatUnits } from "viem";
export const TotalData = () => {
    const [data, setData] = useState({
        hfgSold: 0,
        TotalUSD: 0
    })

    const fetchGraphData = useCallback(async () => {
        try {

            const options = {
                method: 'POST',
                url: graphQlUrl,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        hficoSale(id: 1) {
                          id
                          hfgSold
                          usdcRaised
                          usdtRaised
                        }
                      }`
                }
            };
            const response = await axios.request(options);
            const res = response.data;

            const { hficoSale } = res.data
            const hfgSold = (parseFloat(formatEther(hficoSale?.hfgSold)))
            const usdt = (parseFloat(formatUnits((hficoSale?.usdtRaised),6)))
            const usdc = (parseFloat(formatUnits((hficoSale?.usdcRaised),6)))
            const Total = usdc+usdt
       

            setData({
                hfgSold: hfgSold,
                TotalUSD:(Total)
            })


        } catch (err) {
            console.warn(err)
        }
    }, [ setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 3000);

        return () => clearInterval(inter)
    }, [fetchGraphData])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData])

    return useMemo(() => {
        return {
            data
        }
    }, [data])
}
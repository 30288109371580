import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { formatEther } from "viem";
import { useAddress } from "./useAddress";

export const useGraphData = () => {
    const [data, setData] = useState({
        totalReferral: 0,
        totalHFGBonus: 0,
        referrals: []
    })
    const { address } = useAddress()

    const fetchGraphData = useCallback(async () => {
        try {
            if(!address) return

            const options = {
                method: 'POST',
                url: graphQlUrl,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        hfReferrals(where:{referrer:"${address}"} orderBy:level, orderDirection: asc) {
                            referredUser
                            level
                            amount
                            hfgBonus
                        }

                        hfReferrers(where:{id:"${address}"}) {
                            totalHFBonus
                        }
                    }`
                }
            };
            const response = await axios.request(options);
            const res = response.data;

            const { hfReferrals, hfReferrers } = res.data
            const totalHFBonus = hfReferrers[0]?.totalHFBonus

            const referrals = hfReferrals?.map((referral) => {
                return {
                    ...referral,
                    amount: parseFloat(formatEther(referral.amount)),
                    hfgBonus: parseFloat(formatEther(referral.hfgBonus)),
                }
            })


            setData({
                totalHFGBonus: formatEther(totalHFBonus ?? "0"),
                totalReferral: referrals?.length,
                referrals
            })
        } catch(err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 3000);

        return () => clearInterval(inter)
    }, [fetchGraphData])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData])

    return useMemo(() => {
        return {
            data
        }
    }, [data])
}



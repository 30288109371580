import React from 'react'
import Faqs from '../../Components/Faqs/Faqs'
import BalanceOverview from '../../Components/BalanceOverview/BalanceOverview'
import Banner from '../../Components/Banner/Banner'
import Statisics from '../../Components/Statisics/Statisics'
import BuyOc from '../../Components/BuyOc/BuyOc'
import BuySell from '../../Components/ButSell/BuySell'

const SaleDetails = () => {

    return (
        <>
            <Banner />
            <BuySell />
            <BuyOc />
            <Statisics />
            <Faqs />
        </>
    )
}

export default SaleDetails
import React from 'react'
import { FaTelegramPlane, FaYoutube, FaTwitter, FaMediumM, FaRedditAlien, FaFacebookF } from "react-icons/fa";
import './Footer.css'
import { SocialIcon } from 'react-social-icons';
const Footer = () => {
    return (
        <footer>
            <div className="socialIcons">
                <div className="title">
                    <h2>Easy Connect With our Platform</h2>
                </div>
                <ul>
                    <li>
                        <SocialIcon url="https://t.me/HashFairGames" rel="noreferrer" title="Telegram" target="_blank" fgColor="#fff" />

                    </li>
                
                    <li>
                        <SocialIcon url="https://www.youtube.com/channel/UCt_4P2eqk0Jsn5MNsWapqcA" rel="noreferrer" title="Youtube" target="_blank" fgColor="#fff" />

                    </li>
                    <li>
                        <SocialIcon url="https://twitter.com/Hashfair_Games" rel="noreferrer" title="Twitter" target="_blank" fgColor="#fff" />

                    </li>
                    {/* <li>
                        <SocialIcon url="https://medium.com/@hashfairgames" rel="noreferrer" title="Medium" target="_blank" fgColor="#fff" />

                    </li> */}
                    <li>
                        <SocialIcon url="https://www.reddit.com/r/Hashfair/" rel="noreferrer" title="Reddit" target="_blank" fgColor="#fff" />
                    </li>
                    <li>
                        <SocialIcon url="https://www.facebook.com/hashfairgames" rel="noreferrer" title="Facebook" target="_blank" fgColor="#fff" />

                    </li>
                    <li>
                        <SocialIcon url="https://www.instagram.com/hashfairgames/" rel="noreferrer" title="Facebook" target="_blank" fgColor="#fff" />

                    </li>
                    <li>
                        <SocialIcon url="https://discord.com/invite/yb2UTfUdat" rel="noreferrer" title="Facebook" target="_blank" fgColor="#fff" />

                    </li>
                </ul>
            </div>
            <div className="copyright">
                <p>©  All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer
import React from 'react'
import './PublicSale.css'
import ScrollAnimation from 'react-animate-on-scroll';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import copyIcon from '../../Assets/images/copyicon.png'
import { Link } from 'react-router-dom';
import hashfairnft from '../../Assets/images/hashfair-nft-lvl2.json'
import { url } from '../../config';
import { useAddress } from '../../hooks/useAddress';
import Lottie from 'react-lottie-player';
import { useSaleEligible } from '../../hooks/useSaleEligible';


const PublicSale = () => {
    const id = localStorage.getItem('Active') || ''
    const { address: walletAddress } = useAddress()
    const notify = () => toast.success('Copied Successfully!')

    const { eligible } = useSaleEligible(id)



    return (
        <>

            <section className="publicSale">
                <div className="oc_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="infobox">
                                <ScrollAnimation animateIn="flipInX"
                                    initiallyVisible={true}
                                    animateOnce={true}>
                                    <h1>Unlock Early Access to HFG</h1>
                                    <p className='mt-4 mb-0'>Timing is Key, Rewards Await In HashFair, If Your Referral Invests Before You, Their Early Action Excludes Them from Your Bonus Calculation!</p>
                                    <p className='mt-4 mb-0'>Be Among the First to Experience the HFG Token in the HashFair ICO Rounds</p>
                                    <p className="mt-4 mb-0">Seize the Opportunity, Power the Revolution, Participate in the HashFair ICO Rounds and Unlock the Potential of Decentralized Finance!</p>

                                    {(eligible && walletAddress) &&
                                        <>
                                            <strong className='mt-4 d-block'>Copy Referral Link</strong>
                                            <div className="buttonGrp d-flex topbaner">
                                                {eligible && <CopyToClipboard text={`${url}?referrer=${walletAddress}`}>
                                                    <button onClick={notify} className="oc_btn copyRefferalCode"><span>{`${url.substring(0, 12)} ... ${walletAddress?.slice(-3)}`}</span> <img src={copyIcon} alt="Copy icon" /></button>
                                                </CopyToClipboard>}
                                                {
                                                    !eligible && <button className="oc_btn copyRefferalCode">
                                                        <span>Not eligible ( min. investment of $10 reqd. )</span>
                                                    </button>
                                                }
                                                <Link to="/referdetails"> <button className="oc_btn activityDtl">Referral Activity Details</button></Link>
                                            </div>
                                        </>
                                    }
                                    <div className='d-flex'>
                                    <a className="btnsalebnr me-3" href='#buySection1'>ICO Details</a>
                                    <a className="btnsalebnr" href='https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C1[…]e8F&outputCurrency=0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf' target='blank'>Buy HFG</a>
                                    </div>

                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="imageBox">

                                <Lottie
                                    loop
                                    className='react-player coinbanervideo'
                                    animationData={hashfairnft}
                                    play
                                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default PublicSale
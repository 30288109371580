import React from "react";
import "./ConnectWallet.css";

const ConnectWallet = () => {
  return (
    <>
      <span>
        Connect
      </span>
    </>
  );
};

export default ConnectWallet;

export const rounds = [
    {
        address: "0x3c31E3362B22b140A06e5613206b177Ca0E918Fc",
        price: 0.08,
        startDate: "17 August 2023",
        ocAllotted: '625,000',
        startTime: 1692264599*1000
    },
    {
        address: "0x725d73D826400e9886F17D80d29c15C2A960c27D",
        price: 0.085,
        startDate: "18 August 2023",
        ocAllotted: '1,176,470',
        startTime: 1692350999*1000
    },
    {
        address: "0xD14e68429c13F98B50aac52afF4C34Db207f406d",
        price: 0.09,
        startDate: "19 August 2023",
        ocAllotted: '2,222,222',
        startTime: 1692437399*1000
    },
    {
        address: "0x75B3f9040B4FCcb0011866514C08d3AD00EA04F3",
        price: 0.095,
        startDate: "20 August 2023",
        ocAllotted: '5,263,158',
        startTime: 1692523799*1000
    },
    // {
    //     address: "0x837f4e66Eb259424aCA40A27FbB346fd60e90186",
    //     price: 0.1,
    //     startDate: "21 August 2023",
    //     ocAllotted: '10,000,000',
    //     startTime: 1692610199*1000
    // },

]

        





// [
//     {
//         address: "0xfE72e9Af625203688D76620089ecd1aafC739AF8",
//         status: 0,
//         progress: 100,
//         HfgSold: 625000,
//         UsdtRaised: 0,
//         UsdcRaised: 0,
//     },
//     {
//         address: "0x4966A42971C4B79CA8d739B1C283cEF4fD9d6606",
//         status: 0,
//         progress: 100,
//         HfgSold: 1176470,
//         UsdtRaised: 0,
//         UsdcRaised: 0,
//     },
//     {
//         address: "0xf24E9cD85640F1B82a0982eFB06d0F404882eD79",
//         status: 0,
//         progress: 100,
//         HfgSold: 2222222,
//         UsdtRaised: 0,
//         UsdcRaised: 0,
//     },
//     {
//         address: "0xfF0b31b4145Efba7E93D47ECBf117A4E86744e84",
//         status: 0,
//         progress: 100,
//         HfgSold: 5263158,
//         UsdtRaised: 0,
//         UsdcRaised: 0,
//     },
//     {
//         address: "0x0b82f0155772d9f5E5564662b3A33Fc335fAfbA0",
//         status: 0,
//         progress: 100,
//         HfgSold: 10000000,
//         UsdtRaised: 0,
//         UsdcRaised: 0,
//     },

// ]
import { erc20ABI } from "wagmi"
import OracleABI from "../Assets/Abi/OcRound.json"
import { ocToken, oracleAddress, usdcToken, usdtToken } from "../config"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useContractInstance } from "./useContract"
import { ethers } from "ethers"
import { formatUnits, parseUnits } from "viem"
import { toast } from "react-hot-toast"
import { useAddress } from "./useAddress"

export const saleContract = {
    address: oracleAddress,
    abi: OracleABI
}

export const hfgTokenContract = {
    address: ocToken,
    abi: erc20ABI
}

export const usdtContract = {
    address: usdcToken,
    abi: erc20ABI
}

export const usdcContract = {
    address: usdcToken,
    abi: erc20ABI
}

function mapToKey(abi, method, values) {
    const iface = new ethers.utils.Interface(abi);
    const fragment = iface.getFunction(method);

    const outputs = fragment.outputs.map((o) => o.name);
    if (!values) return null
    if (outputs.length === values.length) {
        const encresult = iface.encodeFunctionResult(fragment, values)
        const decresult = iface.decodeFunctionResult(fragment, encresult)
        return decresult;
    }
    return null
}

export const useContractData = () => {
    const [result, setResult] = useState(null)
    const saleInstance = useContractInstance(oracleAddress, OracleABI)

    const fetchSaleInfo = useCallback(async () => {
        try {
            const sale = await saleInstance.sale()

            console.log(sale);
            const _result = mapToKey(OracleABI, "sale", sale)
            setResult(_result)
        } catch (err) {
       console.error(err);
        }
    }, [saleInstance])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchSaleInfo()
        }, 3000)

        return () => clearInterval(inter)
    }, [fetchSaleInfo])

    useEffect(() => {
        fetchSaleInfo()
    }, [fetchSaleInfo])

    return useMemo(() => {
        return {
            result
        }
    }, [result])
}

export const useSaleContractData = (saleaddress) => {
    const [result, setResult] = useState(null);
    const saleInstance = useContractInstance(saleaddress, OracleABI);

    const fetchSaleInfo = useCallback(async () => {
        try {
            if (!saleaddress) {
                // If 'saleaddress' is null, set a default value or handle the null case
                setResult(null); // Set the result to null or any other default value
                return;
            }

            const sale = await saleInstance.sale();
            const _result = mapToKey(OracleABI, "sale", sale);

            const Status = _result.state == 1 ? saleaddress : '';
            if (Status) {
                localStorage.setItem("Active", Status);
            }

            setResult(_result);
        } catch (err) {
            // Handle errors if needed
        }
    }, [saleInstance, saleaddress]);

    useEffect(() => {
        if (!saleaddress) {
            // If 'saleaddress' is null, return early to prevent unnecessary calls
            return;
        }

        const inter = setInterval(() => {
            fetchSaleInfo();
        }, 4000);

        return () => clearInterval(inter);
    }, [fetchSaleInfo, saleaddress]);

    useEffect(() => {
        fetchSaleInfo();
    }, [fetchSaleInfo]);

    return useMemo(() => {
        return {
            result,
        };
    }, [result]);
};



export const useUserData = () => {
    const { address } = useAddress()

    const [usdcBalance, setUsdcBalance] = useState(0)
    const [usdtBalance, setUsdtBalance] = useState(0)
    const [hfgBalance, setHfgBalance] = useState(0)
    const [usdcAllowance, setUsdcAllowance] = useState(0)
    const [usdtAllowance, setUsdtAllowance] = useState(0)
    const [userInfo, setUserInfo] = useState(null)

    const saleInstance = useContractInstance(oracleAddress, OracleABI)
    const usdtInstance = useContractInstance(usdtToken, erc20ABI)
    const usdcInstance = useContractInstance(usdcToken, erc20ABI)
    const hfgInstance = useContractInstance(ocToken, erc20ABI)

    const fetchUserInfo = useCallback(async () => {
        try {
            // console.log(saleInstance)
            const _userInfo = await saleInstance.users(address)
            const result = (_userInfo.map((e) => e))

            const _result = mapToKey(OracleABI, "users", result)
            setUserInfo(_result)

        } catch (err) {
            console.error(err)
        }
    }, [saleInstance, address])

    const fetchUSDTTokenInfo = useCallback(async () => {
        try {
            const usdtBalance = await usdtInstance.balanceOf(address)
            const _usdtBalance = formatUnits(usdtBalance, 6)
            setUsdtBalance(_usdtBalance)

            const usdtAllowance = await usdtInstance.allowance(address, oracleAddress)
            const _usdtAllowance = formatUnits(usdtAllowance, 6)
            setUsdtAllowance(_usdtAllowance)

        } catch (err) {
            console.error(err)
        }
    }, [usdtInstance, address])

    const fetchUSDCTokenInfo = useCallback(async () => {
        try {
            const usdcBalance = await usdcInstance.balanceOf(address)
            const _usdcBalance = formatUnits(usdcBalance, 6)
            setUsdcBalance(_usdcBalance)

            const hfgBalance = await hfgInstance.balanceOf(address)
            const _hfgBalance = formatUnits(hfgBalance, 18)
            setHfgBalance(_hfgBalance)

            const usdcAllowance = await usdcInstance.allowance(address, oracleAddress)
            const _usdcAllowance = formatUnits(usdcAllowance, 6)
            setUsdcAllowance(_usdcAllowance)
        } catch (err) {
            console.error(err)
        }
    }, [usdcInstance, hfgInstance, address])


    useEffect(() => {
        const inter = setInterval(() => {
            if (address) {
                fetchUserInfo()
                fetchUSDCTokenInfo()
                fetchUSDTTokenInfo()
            }
        }, 4000)

        return () => clearInterval(inter)
    }, [fetchUserInfo, fetchUSDCTokenInfo, fetchUSDTTokenInfo, address])

    useEffect(() => {
        if (address) {
            fetchUserInfo()
            fetchUSDCTokenInfo()
            fetchUSDTTokenInfo()
        }
    }, [fetchUserInfo, fetchUSDCTokenInfo, fetchUSDTTokenInfo, address])


    return useMemo(() => {
        return {
            userInfo,
            usdcAllowance,
            usdtAllowance,
            usdcBalance,
            usdtBalance,
            hfgBalance
        }
    }, [userInfo, usdcAllowance, usdtAllowance, usdcBalance, usdtBalance, hfgBalance])
}



export const useSaleUserData = (saleaddress) => {
    const { address } = useAddress()

    const [usdcBalance, setUsdcBalance] = useState(0)
    const [usdtBalance, setUsdtBalance] = useState(0)
    const [hfgBalance, setHfgBalance] = useState(0)
    const [usdcAllowance, setUsdcAllowance] = useState(0)
    const [usdtAllowance, setUsdtAllowance] = useState(0)
    const [userInfo, setUserInfo] = useState(null)

    const saleInstance = useContractInstance(saleaddress, OracleABI)
    const usdtInstance = useContractInstance(usdtToken, erc20ABI)
    const usdcInstance = useContractInstance(usdcToken, erc20ABI)
    const hfgInstance = useContractInstance(ocToken, erc20ABI)

    const fetchUserInfo = useCallback(async () => {
        try {
            // console.log(saleInstance)
            const _userInfo = await saleInstance.users(address)
            const result = (_userInfo.map((e) => e))

            const _result = mapToKey(OracleABI, "users", result)
            setUserInfo(_result)

        } catch (err) {
            console.error(err)
        }
    }, [saleInstance, address])

    const fetchUSDTTokenInfo = useCallback(async () => {
        try {
            const usdtBalance = await usdtInstance.balanceOf(address)
            const _usdtBalance = formatUnits(usdtBalance, 6)
            setUsdtBalance(_usdtBalance)

            const usdtAllowance = await usdtInstance.allowance(address, saleaddress)
            const _usdtAllowance = formatUnits(usdtAllowance, 6)
            setUsdtAllowance(_usdtAllowance)

        } catch (err) {
            console.error(err)
        }
    }, [usdtInstance, address])

    const fetchUSDCTokenInfo = useCallback(async () => {
        try {
            const usdcBalance = await usdcInstance.balanceOf(address)
            const _usdcBalance = formatUnits(usdcBalance, 6)
            setUsdcBalance(_usdcBalance)

            const usdcAllowance = await usdcInstance.allowance(address, saleaddress)
            const _usdcAllowance = formatUnits(usdcAllowance, 6)
            setUsdcAllowance(_usdcAllowance)

            const hfgBalance = await hfgInstance.balanceOf(address)
            const _hfgBalance = formatUnits(hfgBalance, 18)
            setHfgBalance(_hfgBalance)
        } catch (err) {
            console.error(err)
        }
    }, [usdcInstance, hfgInstance, address])


    useEffect(() => {
        const inter = setInterval(() => {
            if (address) {
                fetchUserInfo()
                fetchUSDCTokenInfo()
                fetchUSDTTokenInfo()
            }
        }, 6000)

        return () => clearInterval(inter)
    }, [fetchUserInfo, fetchUSDCTokenInfo, fetchUSDTTokenInfo, address])

    useEffect(() => {
        if (address) {
            fetchUserInfo()
            fetchUSDCTokenInfo()
            fetchUSDTTokenInfo()
        }
    }, [fetchUserInfo, fetchUSDCTokenInfo, fetchUSDTTokenInfo, address])



    return useMemo(() => {
        return {
            userInfo,
            usdcAllowance,
            usdtAllowance,
            usdcBalance,
            usdtBalance,
            hfgBalance
        }
    }, [userInfo, usdcAllowance, usdtAllowance, usdcBalance, usdtBalance, hfgBalance])
}



export const useContractCall = (contract, abi, method, params) => {
    const { address } = useAddress()
    const contractInstance = useContractInstance(contract, abi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await contractInstance[method](...params)
                resolve(txn.wait)
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                // reject(null)
                resolve(null)
            }
        })
    }, [contractInstance, method, params])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])
}

export const useReferralStored = () => {
    const { address } = useAddress()
    useEffect(() => {
        const url = window.location.href;
        const urlParams = new URLSearchParams(new URL(url).search);
        const referrer = urlParams.get('referrer');
        if (referrer) {
            localStorage.setItem('referrer', referrer);
            if (address && (referrer.toLowerCase() === address.toLowerCase())) {
                localStorage.removeItem('referrer')
            }
        }
    }, [address]);
    const referrerFromStorage = localStorage.getItem('referrer');
    return referrerFromStorage ?? ethers.constants.AddressZero;
}

export const useBuy = () => {
    const { address } = useAddress()
    const contractInstance = useContractInstance(oracleAddress, OracleABI, address !== undefined && address !== null)
    const referrer = useReferralStored()

    const execute = useCallback((token, input) => {
        return new Promise(async (resolve, reject) => {
            console.log(referrer, parseUnits(input, 6))
            try {
                const txn = await contractInstance.buyWith(token, referrer, parseUnits(input, 6))
                resolve(txn.wait)
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [contractInstance, referrer])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])
}


export const useSaleBuy = (saleaddress) => {
    const { address } = useAddress()
    const contractInstance = useContractInstance(saleaddress, OracleABI, address !== undefined && address !== null)
    const referrer = useReferralStored()

    const execute = useCallback((token, input) => {
        return new Promise(async (resolve, reject) => {
            // console.log(referrer, parseUnits(input, 6))
            try {
                const txn = await contractInstance.buyWith(token, referrer, parseUnits(input, 6))
                resolve(txn.wait)
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [contractInstance, referrer])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])
}
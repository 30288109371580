import React, { useEffect, useState } from "react";
import "./Projects.css";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { localeFixed } from "../../config/helpers";
import { useSaleContractData } from "../../hooks/useContractData";
import { formatEther, formatUnits } from "viem";
import { ExternalApi } from "../../hooks/externalApi";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>You missed it!</span>;
  } else {
    // Render a countdown
    return <span>{days}d:{hours}h:{minutes}m:{seconds}s</span>;
  }
};



export const RoundPage = (props) => {
  const navigate = useNavigate()

  const [hfgSold, setHfgSold] = useState(0);
  const [usdcRaised, setUsdcRaised] = useState(0);
  const [usdtRaised, setUsdtRaised] = useState(0);
  const [progress, setProgress] = useState(0)
  const [countdown, setCountdown] = useState(0)
  const [StartIn, setStartIn] = useState(props.startTime)
  const [status, setStatus] = useState(0)

  const { result: data, isLoading } = useSaleContractData(props.address)
  const { result } = ExternalApi(props.address)

  const saleCap = Number((props.ocAllotted).replace(/,/g, ''));
  const navi = () => { navigate(`/saledetails?id=${props.address}`) }

  useEffect(() => {
    if (!isLoading) {
      if (result === false) {
        if (data) {
          const sale = data
          const saleStart = parseFloat(sale.started)
          setStatus(sale.state)
          const hfRaised = sale.hfSold
          const endDate = (((saleStart + 86400) * 1000))
          const totalRaised = parseFloat(formatEther(hfRaised))
          const progress = (totalRaised / saleCap) * 100
          setStartIn(props.startTime)
          setCountdown(endDate)
          setProgress(progress)
          setHfgSold(totalRaised)
          setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
          setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
        }
      } else {
        setStatus(result[0]?.status);
        setProgress(result[0]?.progress);
        setHfgSold(result[0]?.HfgSold);
        setUsdcRaised(result[0]?.UsdcRaised);
        setUsdtRaised(result[0]?.UsdtRaised);

      }
    }
  }, [result, isLoading, data, setHfgSold, setCountdown, setProgress])

  return (
    <div className="col-12 pt-5" id={`buySection${props.idx}`}>
      <div className={`listBoxInner ${status == 1 && "liveline"} borderColors${props.idx}`} style={{ opacity: status === 2 ? 0.5 : 1 }}>
        <div className="infoBox">
          <span className={`${status == 2 && "labelend"} label`}></span>
          <div className="labelbox">
            <strong>Round - {props.idx}</strong>
            <ul>
              <li className={`upcoming ${(props.address) && (status === 0) && "active"}`} ><span></span>Upcoming</li>
              <li className={`saleLive ${(props.address) && (status === 1) && 'active'} `}><span></span>Sale Live</li>
              <li className={`saleEnded ${((props.address) && (status === 2) && 'active')}`}><span></span>Sale Ended</li>
            </ul>
          </div>

          <p className="subtitle">Fair Launch</p>
          <strong className="mt-3">Hardcap</strong>
          <strong>
            {props.ocAllotted} HFG
          </strong>
          <p className="progressBar">
            Progress {localeFixed(progress)}%
          </p>
          <div className="progresLine"><span style={{ width: `${localeFixed(progress)}%` }}></span></div>
          <div className="row tableBox">
            <p className="ttl">
              {localeFixed(hfgSold)} HFG
              <span>
                {saleCap.toLocaleString()} HFG
              </span>
            </p>
            <p> HFG Price: <span>${props.price}</span></p>
            <p>
              HFG Sold:
              <span>
                {localeFixed(hfgSold)} HFG
              </span>
            </p>
            <p>
              USDC Raised:
              <span>
                {localeFixed(usdcRaised)}
              </span>
            </p>
            <p>
              USDT Raised
              <span>
                {localeFixed(usdtRaised)}
              </span>
            </p>
          </div>
          <div className="dividerLine"></div>
          <div className="row saleTineView">

            {(status === 2) && <p className="timesSale">
              Sale Ended
            </p>}

            {(status === 1) && <p className="timesSale">
              Sale Ends In:
              <br />
              <Countdown date={countdown} renderer={renderer} />
            </p>}

            {(status === 0) && <p className="timesSale">
              Sale Starts In:
              <br />
              <Countdown date={StartIn} renderer={renderer} />
            </p>}

            <button className="btnView" style={{ border: 'none' }} disabled={status === 0 && true} onClick={navi}>
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

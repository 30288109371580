import { ethers } from 'ethers'
import { web3Provider } from '../config'

function getSigner ( ) {
    return (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
}

export const useContractInstance = (contractAddress, contractABI, signerIfPossible=false) => {
    
    const contract = new ethers.Contract(contractAddress, contractABI, signerIfPossible ? getSigner() : web3Provider )
    return contract
}
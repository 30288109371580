import React, { useEffect, useState } from 'react'
import './BuySell.css'
import ScrollAnimation from 'react-animate-on-scroll';
import { defaultNetwork, ocToken } from '../../config';

import { CProgress, CProgressBar } from '@coreui/react';
import { useSaleContractData } from '../../hooks/useContractData';
import { formatEther } from 'viem';
import { useSwitchNetwork } from 'wagmi';
import { localeFixed } from '../../config/helpers';
import { addToken } from '../../utils/addToken';
import { useLocation } from 'react-router';
import { rounds } from '../../config/rounds';
import { ExternalApi } from '../../hooks/externalApi';



const BuySell = () => {
  const location = useLocation()
  const [progress, setProgress] = useState(0)
  const [hfgSold, setHfgSold] = useState(0)
  const [id, setid] = useState(0)

  const saleCap = Number(rounds.filter(data => data.address === id)[0]?.ocAllotted.replace(/,/g, ''));
  const { result: data, isLoading } = useSaleContractData(id)
  const { result } = ExternalApi(id)

  const addMainnetToMetaMask = async () => {
    if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask and try again.');
        return;
    }

    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x89', // BSC Testnet chainId in hexadecimal
                    chainName: 'Polygon',
                    nativeCurrency: {
                        name: 'MATIC',
                        symbol: 'MATIC',
                        decimals: 18,
                    },
                    rpcUrls: ["https://polygon-rpc.com"],
                    blockExplorerUrls: ['https://polygonscan.com/'],
                },
            ],
        });
    } catch (error) {
        console.error(error);
        alert('Error adding Polygon to MetaMask: ' + error.message);
    }
}


  useEffect(() => {
    if (!isLoading) {
      if (result === false) {
        if (data) {
          const sale = data;
          const hfRaised = sale.hfSold;
          const totalRaised = parseFloat(formatEther(hfRaised));
          const progress = (totalRaised / saleCap) * 100;

          setProgress(progress);
          setHfgSold(totalRaised);
        }
      } else {
        setProgress(result[0].progress);
        setHfgSold(result[0].HfgSold);
      }
    }
  }, [result, data, isLoading, setProgress, setHfgSold]);



  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    setid(id)
  }, [])


  return (
    <section className="buySell">

      <div className="oc_container">
        <div className="row">
          <div className="col-sm-12">
            <div className="buySellbox">
              <ScrollAnimation animateIn='fadeIn'
                initiallyVisible={true}
                animateOnce={true}>
                <CProgress className="mb-3">
                  <CProgressBar variant="striped" animated value={progress} title='HFG Sale' />
                </CProgress>
                <div className="oc_row align-items-center space-between">
                  <span>{localeFixed(hfgSold)} HFG SOLD</span>

                  <span>{saleCap.toLocaleString()} Hardcap
                  </span>
                </div>
                <div class="buttonGrp">
                  <button class="oc_btn" onClick={() => {
                 addMainnetToMetaMask()
                  }} >Add Polygon Mainnet</button>
                  <button class="oc_btn" onClick={() => {
                    addToken({
                      data: {
                        address: ocToken,
                        symbol: 'HFG',
                        decimals: 18,
                        name: "HashFair Games"
                      }
                    })
                  }} >Add HFG To Metamask</button>
                  <a href={`https://polygonscan.com/address/${id}`} rel="noreferrer" style={{ textDecoration: "none" }} class="oc_btn" target="_blank">View Smart Contract </a>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuySell
import React, { useCallback, useEffect, useState } from 'react'
import './RefDetails.css'
// import '../../Components/PublicSale/PublicSale.css'
import Tooltip from "react-simple-tooltip"
import { css } from "styled-components"
import { toast } from 'react-hot-toast';
import copyIcon from '../../Assets/images/copyicon.png'
import { localeFixed } from '../../config/helpers';
import FaqsRef from '../../Components/Faqs/FaqsRef'
import { useGraphData } from '../../hooks/useGraph';

import CopyToClipboard from 'react-copy-to-clipboard';
import { url } from '../../config';
import Pagination from '@mui/material/Pagination';
import { useEligible } from '../../hooks/useEligible';
import { useAddress } from '../../hooks/useAddress';
import { useSaleEligible } from '../../hooks/useSaleEligible';


const Ref = () => {
    const id = localStorage.getItem('Active') || ''

    const { data: { totalHFGBonus, totalReferral, referrals } } = useGraphData()
    const { address: walletAddress } = useAddress()

    const [pagination, setPagination] = useState([]);

    const pageHandler = useCallback(() => {
        const PageCount = Math.ceil(Number(referrals.length) / 10)
        setPagination(PageCount)
    }, [referrals])


    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }


    useEffect(() => {
        pageHandler()
    }, [pageHandler])


    useEffect(() => {
        pageHandler()
    }, [pageHandler])

    // Pagination Code End 
    const { eligible } = useSaleEligible(id)

    return (
        <>
            <section className="mt-5 refDetails">
                <div className="oc_container">
                    <div className="title">
                        <h2>Referral Details</h2>
                    </div>
                    <div className="projects">
                        <div className="oc_container">
                            {/* List Box */}
                            <div className="row mb-5">
                                <div className="col-md-12 col-lg-12">
                                    <div className="row countBox">
                                        {/* <div className="col-sm-4">
                                            <div className="countinbox text-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><script xmlns="" />
                                                    <path fill="var(--ci-primary-color, currentColor)" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" class="ci-primary" />
                                                </svg>
                                                <p>Referral claimed</p>
                                                <strong>{ }</strong>
                                            </div>
                                        </div> */}
                                        <div className="col-sm-3">
                                            <div className="countinbox text-center">
                                                <svg baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" overflow="visible" className="sc-5f2a7416-0 jbewcx"><g fill="currentColor"><path d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path fill-opacity="0.302" d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path fill-opacity="0.302" d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path fill-opacity="0.302" d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path fill-opacity="0.302" d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path fill-opacity="0.302" d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path><path fill-opacity="0.302" d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></g></svg>

                                                <p>No. of referrals</p>
                                                <strong>{totalReferral}</strong>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="countinbox text-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><script xmlns="" />
                                                    <path fill="var(--ci-primary-color, currentColor)" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" class="ci-primary" />
                                                </svg>
                                                <p>Total Referral Bonus </p>
                                                <strong>{totalHFGBonus} HFG</strong>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="countinbox text-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><script xmlns="" />
                                                    <path fill="var(--ci-primary-color, currentColor)" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" class="ci-primary" />
                                                </svg>
                                                <p>Referral Percent </p>
                                                <strong>2%</strong>
                                            </div>
                                        </div>
                                        <div className='col-sm-3 text-center'>
                                            {walletAddress && eligible &&
                                                <>
                                                    <strong className='mt-4 d-block'>Copy Referral Link</strong>
                                                    <div className="buttonGrp d-flex justify-content-center">
                                                        <CopyToClipboard text={`${url}?referrer=${walletAddress}`}>
                                                            <button onClick={() => {
                                                                toast.success('Copied Successfully')
                                                            }} className="oc_btn copyRefferalCode" style={{ backgroundColor: "white", color: "black" }}><span>{`${url.substring(0, 12)} ... ${walletAddress.slice(-3)}`}</span> <img src={copyIcon} alt="Copy icon" className='text-white' /></button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </>
                                            }
                                            {
                                                !eligible && <>
                                                    <strong className='mt-4 d-block'>Referral Link</strong>
                                                    <div className="buttonGrp d-flex justify-content-center text-center">
                                                        <p>
                                                            <span>Do a minimum investment of $10,<br/>to get the referral link</span>
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="refDetailsList oc_container">
                        <div className='table-responsive'>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Sr.no</th>
                                        <th>Wallet Address</th>
                                        <th>HFG Bought</th>
                                        <th>Bonus Amount (HFG)</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {referrals ? referrals.slice(indexOfFirst, indexOfLast).map((pack, i) => (
                                        <tr key={i}>
                                            <td>{i + indexOfFirst + 1}</td>
                                            <td>
                                                <Tooltip content={pack.referredUser} customCss={css` white-space: nowrap;`}>
                                                    <span>{pack.referredUser.substring(0, 4)} ... {pack.referredUser.slice(-4)}</span>
                                                </Tooltip>
                                            </td>

                                            <td>{localeFixed(pack.amount)} HFG</td>
                                            <td>{localeFixed(pack.hfgBonus)} HFG</td>
                                            {/* <td>50,000</td> */}
                                        </tr>
                                    )) : <>
                                        <tr>
                                            <td colSpan={4}><p className='text-center m-4'>No Data Found</p></td>
                                        </tr>
                                    </>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {referrals.length == 0 ?
                    ''
                    : <>
                        {/* {pagination <= 1 ? '' : <div > */}
                        <Pagination className='myPagination'  onChange={pagechange} count={pagination} />
                        {/* </div>} */}
                    </>
                }
            </section>
            <section className="claimBonus" style={{ display: 'none' }}>
                <div className="oc_container">
                    <div className="buttonGrp">
                        <button className="oc_btn" onClick={() => { }} >Claim Bonus</button>
                        <br />
                        {/* {TimeStatus ? <p>Next Claim after <Countdown date={Timer} /></p> : false} */}
                        <br />
                        <br />
                        <p>You can claim after 60 days, when sale ends</p>
                        <p>You will be claiming 10% of your referral earnings in an interval of 30 days, total 10 claims.</p>
                    </div>
                </div>
            </section>

        </>

    )
}

export default Ref
import { ethers } from "ethers"
import Web3 from "web3"
import { InjectedConnector } from 'wagmi/connectors/injected'

export const oracleAddress = "0x3ED7cfEe07C5Effa4729f92521F2cc7b6859e9F0" // Mainnet

export const ocToken = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf"  // Mainnet HFG Token

export const usdtToken = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F" // Mainnet
export const usdcToken = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174" // Mainnet

// export const rpcUrl = "https://alpha-alpha-bird.matic-testnet.quiknode.pro/2d46a76d8bd8d402b6d048a255c308f77f684057/" 

export const rpcUrl = "https://burned-frequent-theorem.matic.quiknode.pro/1a12511ca47f77e315007642fd9cf941eda74306/" // Mainnet

export const defaultWeb3 = new Web3(rpcUrl)
export const web3Provider = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)

export const url = `https://sale.hashfair.io/`

export const defaultNetwork = 137

export const saleTenure = 1 * 24 * 60 * 60

export const graphQlUrl = "https://api.thegraph.com/subgraphs/name/whyusethis/ico-sale-api"


export const connector = new InjectedConnector()

//Seed Sale Info
export const hardCap = "2,000,000"
export const userCap = 50_000
import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
            title: "Which wallet should I use to participate in HashFair Games ICO Sale?",
            content: `HashFair Games ICO Sale is compatible with various crypto wallets. However, we recommend using Trust Wallet or Metamask Wallet for the best user experience and seamless integration with the platform.`,
        },
        {
            title: "Will I Get referral on referring a user to buy HFG?",
            content: `You will receive a referral when a user uses your referral link & buys HFG in ICO Sale rounds.`,
        },
        // {
        //     title: "When does the HashFair Games Seed Round begin and how long does it last?",
        //     content: `The HashFair Games Seed Round starts on July 1st, 2023.`,
        // },
        {
            title: "When does the HashFair Games ICO Sale Round begin and how long does it last?",
            content: `The HashFair Games Sale Round starts on August 17th, 2023 to August 21st 2023.`,
        },
        {
            title: "What can I expect from the Sale Rounds of HashFair Games?",
            content: `During the Sale Round, playable tokens will be distributed to participants. It serves as an initial opportunity to contribute and acquire tokens for engagement within the HashFair Games platform.`,
        },
        {
            title: "Are there referral bonuses available during the Sale Rounds?",
            content: ` Yes, there is a referral program with one level. The level offers a referral bonus of 2%.`,
        },
        {
            title: "Are there any requirements to claim the referral bonuses?",
            content: `Yes, to claim the referral bonuses, you need to meet the minimum criteria of contribution in the Sale Round. The exact contribution amount required may vary, so it is advisable to refer to the official guidelines or documentation provided by HashFair Games for the specific requirements.`,
        },
        {
            title: "What is the vesting period for HashFair tokens?",
            content: `The vesting period for HashFair tokens is 1 month. This means that after the ICO sale completes, there will be a waiting period of 1 month before token holders can access their purchased tokens.`,
        },
        {
            title: "How will the token release work during the vesting period?",
            content: `After the 1-month vesting period, the HashFair tokens will be released gradually. Specifically, 10% of the total tokens purchased will be released every 5th day.`,
        },
       
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#ffffff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#ffffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs">
            <div className="oc_container">
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Banner.css";
import { Link } from "react-router-dom";
import rocket from "../../Assets/images/salebanner1.svg";

const items = [
  // <>
  //   <div className="item" data-value="1">
  //     <div className="bannerinfo p-4 p-md-5 mt-0">
  //       <div className="row align-items-center justify-content-center h-100">
  //         <div className="col-12 col-md-6">
  //           <h2 className="bannermainheading">HashFair Seed Sale is Live</h2>
  //           <p className="bannertext">
  //             Buy HashFair tokens and get a chance to win a free HashFair NFT!
  //           </p>
  //           <div className="bannerbuttons mt-5">
  //             <a className="bannerbtn" href="#buySellSection">Buy Now</a>
  //             <Link to="/referdetails"><button className="bannerbtn ms-3">Refer</button></Link>
  //           </div>
  //         </div>
  //         <div className="col-12 col-md-6">
  //           <div className="bannerimage text-center mx-auto">
  //             <img src={rocket} className="rocketimg" alt="salebanner1" />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </>,
  <>
    <div className="item" data-value="2">
      <div className="bannerinfo p-4 p-md-5 mt-0">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-12 col-md-6">
            <h2 className="bannermainheading">Referral Bonus</h2>
            <p className="bannertext" style={{fontSize:'20px'}}>Referral program offers a one-level 2% bonus for each successful referral.</p>
          </div>
          <div className="col-12 col-md-6">
            <div className="bannerimage text-center mx-auto">
              <img src={rocket} className="rocketimg" alt="salebanner1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

];
const Banner = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: {
      items: 1,
      itemsFit: "fill",
    },
  };
  return (
    <section className="bannerSection mt-lg-5">
      <div className="oc_container">
        <AliceCarousel
          mouseTracking
          // infinite
          autoPlayInterval={3000}
          animationDuration={1500}
          disableButtonsControls
          items={items}
          responsive={responsive}
          autoPlay
        />
      </div>
    </section>
  );
};

export default Banner;

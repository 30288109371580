import React, { useEffect, useState } from "react";
import "./Statisics.css";
import "react-toastify/dist/ReactToastify.css";

//core ui imports
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import { localeFixed } from "../../config/helpers";
import { useSaleContractData } from "../../hooks/useContractData";
import { formatEther, formatUnits } from "ethers/lib/utils";
import UserStatistics from "./UserStatistics";
import { useAddress } from "../../hooks/useAddress";
import { useLocation } from "react-router";
import { rounds } from "../../config/rounds";
import { ExternalApi } from "../../hooks/externalApi";

const Statisics = () => {
  const [id, setid] = useState(0)
  const location = useLocation()
  const { address } = useAddress()
  const [hfgSold, setHfgSold] = useState(0);
  const [usdRaised, setUsdRaised] = useState(0);
  const [usdcRaised, setUsdcRaised] = useState(0);
  const [usdtRaised, setUsdtRaised] = useState(0);

  const saleCap = Number(rounds.filter(data => data.address === id)[0]?.ocAllotted.replace(/,/g, ''));
  const salePrice = rounds.filter(data => data.address === id)[0]?.price;

  const { result: data, isLoading } = useSaleContractData(id)
  const { result } = ExternalApi(id)


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    setid(id)
  }, [])


  useEffect(() => {
    if (!isLoading) {
      if (result == false) {
        if (data) {
          setUsdRaised(formatUnits(data.totalRaised, 6))
          const sale = data
          const hfRaised = sale.hfSold
          const totalRaised = parseFloat(formatEther(hfRaised))
          setHfgSold(totalRaised)
          setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
          setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
        }
      } else {
        setUsdRaised(result[0].UsdcRaised +result[0].UsdtRaised)
        setHfgSold(result[0].HfgSold);
        setUsdcRaised(result[0].UsdcRaised);
        setUsdtRaised(result[0].UsdtRaised);
        // setUsdRaised(1000)
        // setUsdcRaised(500)
        // setUsdtRaised(500)
        // setHfgSold(1000)

      }
    }
  }, [result, data, setHfgSold, setUsdRaised, isLoading])

  return (
    <>
      <section className="statisics">
        <div className="oc_container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="boxinner leftinfo">
                <CAccordion flush>
                  <CAccordionItem itemKey={1}>
                    <CAccordionHeader>
                      <h2>STATISTICS</h2>
                      <div className="row justify-content-center align-items-center w-100 mx-auto">
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>Sale Supply</p>
                            <strong>{saleCap.toLocaleString()} HFG</strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>HFG Sold</p>
                            <strong>
                              {localeFixed(hfgSold)} HFG
                            </strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <div className="m-0 inputlabel">
                            <p>Total Raised</p>
                            <strong>
                              ${localeFixed(usdRaised)}
                            </strong>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 text-center stat-info">
                          <p className="m-0 viewbtn d-flex align-items-center justify-content-center mx-auto mx-md-0 ms-md-auto">
                            View Details
                            <span className="arrow d-inline-block ms-2">
                              &#10095;
                            </span>
                          </p>
                        </div>
                      </div>
                    </CAccordionHeader>
                    <CAccordionBody>
                      <div className="row">
                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{saleCap.toLocaleString()}</p>
                            <p>Sale Supply</p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>${localeFixed(saleCap * salePrice)}</p>
                            <p>HardCap</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{localeFixed(usdtRaised)}</p>
                            <p>USDT Raised</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 text-center">
                          <div className="box">
                            <p>{localeFixed(usdcRaised)}</p>
                            <p>USDC Raised</p>
                          </div>
                        </div>
                      </div>
                    </CAccordionBody>
                  </CAccordionItem>
                </CAccordion>
              </div>
            </div>
            {address && <div className="col-md-12 col-sm-12">
              <div className="boxinner rightinfo">
                <UserStatistics />
              </div>
            </div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default Statisics;

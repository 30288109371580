import React from 'react'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Projects from '../../Components/Projects/Projects'
import Faqs from '../../Components/Faqs/Faqs'
import SaleDetailList from '../../Components/SaleDetailList/SaleDetailList'

const Sale = () => {


    return (
        <>
            <PublicSale />
            <SaleDetailList />
            <Projects />
            <Faqs />
        </>
    )
}

export default Sale
import React, { useEffect, useState } from 'react'
import logo from '../../Assets/images/logo.gif'
import './header.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { FaSignOutAlt } from "react-icons/fa";
import { defaultNetwork, url } from '../../config'
import ConnectWallet from '../ConnectWallet/ConnectWallet'
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'

import { polygon, polygonMumbai } from 'wagmi/chains'
import { useWeb3Modal } from "@web3modal/react";
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useNavigate } from 'react-router';

const notify = () => toast.success('Copied Successfully!')



const Header = () => {
  const { chain } = useNetwork()
  const { setDefaultChain } = useWeb3Modal();
  const { disconnect } = useDisconnect()

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  // Setting Default Chain
  setDefaultChain(polygon);

  // get wallet address 
  const { address } = useAccount()

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: defaultNetwork
  })

  const [Accounts, setAccounts] = useState(null)


  useEffect(() => {
    setAccounts(address)
  }, [address])



  async function disconnectwallet() {
    localStorage.removeItem('wagmi.injected.shimDisconnect')
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
    setAccounts()
  }

  const handleConnectWalletClick = () => {
    connect();
    localStorage.setItem('wagmi.injected.shimDisconnect', true);
    if (!window.ethereum) {
      toast.error('No wallet detected!');
    }
  };
  const navigate = useNavigate();


  return (
    <>
      <nav className="navbar">
        <div className="navbar_inner oc_row space-between align-items-center">
          <div className="logo">
            <a href={"/#"} target="" rel='noreferrer' onClick={() => navigate('/')}>
              <img src={logo} alt="logo" width="140" height="140" />
            </a>
          </div>
          <div className={`nav-elements`}>
          </div>
          <div className="balancebox">
            <ul>
              {Accounts && <>
                {/* <li><strong>{localeFixed(ocBal)}</strong>HFG</li> */}
                {address &&
                  <>
                    {chain.id !== defaultNetwork && <li onClick={() => {
                      switchNetworkAsync?.().then(() => { }).catch(() => { })
                    }} style={{ backgroundColor: "#bc4242", padding: "10px", borderRadius: "20px", cursor: "pointer" }}>
                      Wrong Network
                    </li>}
                  </>
                }
                <li onClick={notify}>
                  <CopyToClipboard text={Accounts}>
                    <span> {Accounts && <> {Accounts.substring(0, 3)} ... {Accounts.slice(-3)}</>}</span>
                  </CopyToClipboard>
                </li>
                <li>
                  <span className='logoutSn' onClick={() => {
                    disconnectwallet()
                    disconnect()
                  }}>Disconnect<FaSignOutAlt />  </span>
                </li>
              </>
              }
              {!Accounts &&
                <li>
                  <span onClick={() => {
                  handleConnectWalletClick()
                  }}><div><ConnectWallet /></div>  </span>
                </li>
              }
            </ul>
          </div>
        </div>
      </nav>

    </>
  )
}

export default Header
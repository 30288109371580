import React from 'react'
import './SaleDetailList.css'

const SaleDetailList = () => {

    return (
        <>
            <section className="saleDetails">
                <div className="oc_container">
                    <div className="title">
                        <h2>Sale Details</h2>
                    </div>
                    <div className="saleDetailsList">
                        {/* <p>
                            We're thrilled to announce that our unique HashFair Seed Sale is Live Now! This is a one-of-a-kind opportunity that comes with only a single round of sale. It's time to gear up and prepare your wallets for an exciting journey ahead, at a price of <b>$0.075</b>
                        </p> */}

                        <p>
                            The HashFair ICO Rounds is your golden ticket to secure HFG tokens at an early stage. This is a rare chance to become part of the HashFair community and contribute to our vision of revolutionizing the blockchain space.

                        </p>

                        <p>
                            In addition to the Seed Sale, we'd also like to inform you that the ICO (Initial Coin Offering) will be live on 17 Aug 2023. The ICO offers various pricing options, and there are a total of 5 rounds available. This provides more opportunities for interested individuals to participate in the project.

                        </p>

                        <p>
                            Take advantage of this rare chance to join the HashFair community and be part of the blockchain revolution. Stay tuned for further details on the sale date and process. Gear up and prepare your wallets for the HashFair ICO - Your gateway to the future of blockchain!
                        </p>
                    </div>
                </div>
            </section>
            {/* Show Details */}

        </>

    )
}

export default SaleDetailList